<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Email Response</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="email_response.title"
                  :error="$v.email_response.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.email_response.title.$error">Name is required</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

<!--            <v-col cols="12">-->
<!--              <v-autocomplete-->
<!--                  v-model="email_response.user_id"-->
<!--                  :search-input.sync="userSearch"-->
<!--                  :items="items"-->
<!--                  :loading="isLoading"-->
<!--                  item-text="display_text"-->
<!--                  item-value="id"-->
<!--                  chips-->
<!--                  deletable-chips-->
<!--                  hide-selected-->
<!--                  outlined-->
<!--                  dense-->
<!--                  clearable-->
<!--              >-->
<!--                <template v-slot:no-data>-->
<!--                  <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                      <v-list-item-title>-->
<!--                        <strong> Search by Name, Email, Unique Identifier</strong>-->
<!--                      </v-list-item-title>-->
<!--                    </v-list-item-content>-->
<!--                  </v-list-item>-->
<!--                </template>-->
<!--                <template v-slot:label>-->
<!--                  User-->
<!--                </template>-->
<!--              </v-autocomplete>-->
<!--            </v-col>-->

            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="email_response.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.email_response.description.$error">Description Field is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="email_response.is_active"
                  :label="email_response.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            @click="closeDialog"
        >
          Cancel
        </v-btn>

        <v-btn
            v-if="checkIsAccessible('email-response', 'create') || checkIsAccessible('email-response', 'edit')"
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import EmailResponseService from "@/services/cms/email-response/EmailResponseService";
import UserService from "@/services/user/UserService";

const emailResponse = new EmailResponseService();
const user = new UserService();
export default {
  name: "CreateOrUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      isLoading: false,
      users: [],
      errors: [],
      userSearch: null,
      email_response: {
        title: '',
        user_id: '',
        description: '',
        is_active: true,
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
        allowedContent: true
      }
    }
  },
  validations: {
    email_response: {
      title: { required },
      description: { required },
      is_active: { required }
    }
  },
  computed: {
    items() {
      return this.users.map((user) => {
        const display_text = user.full_name+' || ' + user.email;
        return Object.assign({}, user, { display_text });
      });
    }
  },
  watch: {
    userSearch (val) {
      if (this.isLoading) return;
      this.isLoading = true;
      if(val != null) {
        user
            .getAllByUserType({name: val})
            .then((response) => {
              this.users =response.data.users
            })
            .catch(err => {
              // console.log(err)
            });
      }
      this.isLoading = false;
    }
  },
  methods: {
    closeDialog(){
      this.dialog = false;
    },
    createEmailResponse(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editEmailResponse(email_response){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.email_response = email_response;
      this.getAllUser();
    },
    getAllUser() {
      user
          .getAllByUserType()
          .then((response) => {
            this.users = response.data.users
          })
          .catch(err => {
            // console.log(err)
          });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      emailResponse
          .create(this.email_response)
          .then(response =>{
            this.loading = false;
            this.$snotify.success("EmailResponse created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      emailResponse
          .update(this.email_response.id,this.email_response)
          .then(response => {
            this.loading = false;
            this.$snotify.success("EmailResponse updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.users = [];
      this.email_response = {
        title: '',
        user_id: '',
        description: '',
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

</style>
